.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.wallet-modal .modal-content{
   background-color: #000;
   color: #fff;
}

.wallet-modal .modal-header,.wallet-modal .modal-footer{
   border-color:#333;
}
form h4{
  padding-bottom:6px;
  padding-top:6px;
}
.sc-button.style.style-2.block{
  width:100%;font-size:24px
}
.sc-button.style.letter.style-2.block span{
  font-size:24px;
}
.sc-button.style.letter.style-2.block  span::before {

    font-size: 24px;
    left: -9px;
    top: 6px;
  }
.not-enough-mika{
  margin-top:26px;
}
a.uniswap img {
  height: 50px;
  width: 50px;

  background:#ffff;
  border-radius:50%;
  margin-left:-10px;
}
a.uniswap {
  margin:20px 0px 0px 0px
}
.form-create-item .sc-button {
    padding: 17px 22px;
  }
